export const columns = [
{
    title: '政策名称',
    dataIndex: 'name',
    key: 'name',
    width:150,
    align:'center',
    ellipsis:true
},
{
    title: '有效时间',
    dataIndex: 'validityStart',
    key: 'validityStart',
    width:250,
    align:'center',
    scopedSlots: { customRender: 'validityStart'}
},
{
    title: '限制次数',
    dataIndex: 'limitCount',
    key: 'limitCount',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '参与折扣',
    dataIndex: 'joinRebate',
    key: 'joinRebate',
    /*width:150,*/
    align:'center',
    scopedSlots: { customRender: 'joinRebate'}
},
{
    title: '参与积分',
    dataIndex: 'joinIntegral',
    key: 'joinIntegral',
    /*width:150,*/
    align:'center',
    scopedSlots: { customRender: 'joinIntegral'}
},
{
    title: '审核状态',
    dataIndex: 'authStatus',
    key: 'authStatus',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'authStatus'}
},
{
    title: '审核人姓名',
    dataIndex: 'authUser',
    key: 'authUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '审核时间',
    dataIndex: 'authDate',
    key: 'authDate',
    /*width:150,*/
    align:'center',
},
{
  title: '关闭人姓名',
  dataIndex: 'closeUser',
  key: 'closeUser',
  /*width:150,*/
  align:'center',
  ellipsis:true
},
{
  title: '关闭时间',
  dataIndex: 'closeDate',
  key: 'closeDate',
  /*width:150,*/
  align:'center',
  ellipsis:true
},
{
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width:150,
    align:'center',
    ellipsis:true
},
// {
//   title: '操作',
//   key: 'operation',
//   fixed: 'right',
//   align: 'center',
//   width: 160,
//   scopedSlots: { customRender: 'action' },
// },
];


export const ProColumns = [
  {
    title: '商品图片',
    dataIndex: 'imgUrl',
    key: 'imgUrl',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'imgUrl'}
  },
  {
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '品牌',
    dataIndex: 'brandName',
    key: 'brandName',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '分类',
    dataIndex: 'categoryName',
    key: 'categoryName',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '规格',
    dataIndex: 'specStr',
    key: 'specStr',
    /*width:150,*/
    align:'center',
  },

  // {
  //   title: '建议零售价',
  //   dataIndex: 'price',
  //   key: 'price',
  //   /*width:150,*/
  //   align:'center',
  //   ellipsis:true
  // },
  {
    title: '标准开票价（D价）',
    dataIndex: 'sellPrice',
    key: 'sellPrice',
    /*width:150,*/
    align:'center',
    ellipsis:true
  }
  // {
  //   title: '政策价格',
  //   dataIndex: 'joinIntegral',
  //   key: 'joinIntegral',
  //   /*width:150,*/
  //   align:'center',
  //   ellipsis:true
  // },
  // {
  //   title: '政策折扣率',
  //   dataIndex: 'authStatus',
  //   key: 'authStatus',
  //   /*width:150,*/
  //   align:'center',
  //   ellipsis:true
  // },
  // {
  //   title: '操作',
  //   dataIndex: 'action',
  //   key: 'action',
  //   /*width:150,*/
  //   align:'center',
  //   ellipsis:true,
  //   scopedSlots: { customRender: 'action'}
  // }
]
